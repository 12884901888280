export const source = {
    accessLevel: 'access_level',
    email: 'email',
    employeeId: 'employee_id',
    firstName: 'first_name',
    lastName: 'last_name',
    middleInitial: 'middle_initial',
    producerId: 'producer_id',
    states: 'states',
    showDraft: 'show_draft',
    showExpired: 'show_expired',
    showArchived: 'show_archived',
    showScheduled: 'show_scheduled',
    roleId: 'role_id',
    roles: 'roles',
};

export const accessLevelFilterTypes = [
    { id: '', name: 'All' },
    { id: 'agent', name: 'Agents' },
    { id: 'manager', name: 'Managers' },
];


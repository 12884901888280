import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { apiPatch, apiGet } from '@src/services/api/helpers';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CancelIcon from '@material-ui/icons/Cancel';

import { linkToRecord } from 'react-admin';

import { Permissions } from '@src/global/permissions';
import { classToResource } from '@src/utils/model-helpers';

const useStyles = makeStyles(() => ({
    main: {
        position: 'absolute',
        left: 'calc(100% - 350px)',
        color: '#000',
        background: '#fff',
        top: '48px',
        width: '300px',
        maxHeight: '400px',
        overflowY: 'scroll',
        borderRadius: '5px',
    },
    menu: {
        cursor: 'pointer',
        paddingRight: '16px',
        paddingLeft: '16px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '32px',
        padding: '16px',
    },
    list: {
        'li&:nth-child(odd)': {
            backgroundColor: '#eee',
        },
    },
    item: {
        cursor: 'pointer',
        width: '100%',
    },
}));

const AdminNotifications = () => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [adminNotifications, setAdminNotifications] = useState([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        apiGet('/admin_notifications').then((response) => {
            const { data } = response;

            setAdminNotifications(data.admin_notifications);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading]);

    const handleClick = useCallback(() => {
        setOpen(!isOpen);
        apiGet('/admin_notifications').then((response) => {
            const { data } = response;

            setAdminNotifications(data.admin_notifications);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading, setOpen, isOpen]);

    const handleClickAway = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleReviewClick = useCallback((id) => {
        apiPatch(`/admin_notifications/${id}/review`).then(() => {
            setAdminNotifications(adminNotifications.filter((an) => an.id !== id));
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [adminNotifications]);

    const handleItemClick = useCallback((an) => {
        const resource = classToResource(an.record_type);
        if (resource === 'social_sharings' || resource === 'field_management_news' || resource === 'articles') {
            return null;
        }

        if (Permissions(resource, 'update') === false) {
            return null;
        }

        const linkToResource = linkToRecord(`/${resource}`, an.resource.id, 'edit');
        window.location.href = linkToResource;
    }, []);

    return (
        <>

            <Badge badgeContent={adminNotifications.length} color="primary">
                <NotificationsNoneIcon />
            </Badge>
            <a
                className={classes.menu}
                onClick={handleClick}
            >
                NOTIFICATIONS
            </a>
            {
                isOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Grid container spacing={2} radius={10} className={classes.main}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6" className={classes.title}>
                                    <NotificationsNoneIcon />
                                    NOTIFICATIONS
                                </Typography>
                                <div className={classes.demo}>
                                    {
                                        isLoading && (
                                            <p>Loading...</p>
                                        )
                                    }
                                    {
                                        !isLoading && (
                                            <List>
                                                {
                                                    adminNotifications.map((an) => {
                                                        return (
                                                            <ListItem key={an.id} className={classes.list}>
                                                                <ListItemIcon>
                                                                    <CancelIcon
                                                                        className={classes.item}
                                                                        fontSize="small"
                                                                        onClick={() => handleReviewClick(an.id)}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    className={classes.item}
                                                                    primary={an.title}
                                                                    secondary={an.note ? an.note : null}
                                                                    onClick={() => handleItemClick(an)}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                        )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </ClickAwayListener>
                )
            }
        </>
    );
};

AdminNotifications.propTypes = {
    resource: PropTypes.string,
};

export default AdminNotifications;

import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { BooleanInput, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { Field } from 'react-final-form';

import { source } from '../data';

const AdminForm = () => (
    <>
        <Field
            type="hidden"
            name={source.accessLevel}
            component="input"
        />
        <Grid container>
            <Grid item xs={12} md={6}>
                <TextInput
                    fullWidth
                    label="Employee ID"
                    source={source.employeeId}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    fullWidth
                    source={source.email}
                />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={5}>
                <TextInput
                    fullWidth
                    source={source.firstName}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <TextInput
                    fullWidth
                    source={source.middleInitial}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <TextInput
                    fullWidth
                    source={source.lastName}
                />
            </Grid>
        </Grid>
        <h3>Access Group</h3>
        <Grid container>
            <Grid item xs={12} md={12}>
                <ReferenceInput
                    required
                    label="Group Access"
                    source={source.roleId}
                    reference="roles"
                >
                    <SelectInput
                        fullWidth
                        emptyText="Select Role"
                    />
                </ReferenceInput>
            </Grid>
        </Grid>
        <h3>Display Preferences</h3>
        <Grid container>
            <Grid item xs={6} md={3}>
                <BooleanInput
                    label="Draft Content"
                    source={source.showDraft}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <BooleanInput
                    label="Archived Content"
                    source={source.showArchived}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <BooleanInput
                    label="Expired Content"
                    source={source.showExpired}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <BooleanInput
                    label="Scheduled Content"
                    source={source.showScheduled}
                />
            </Grid>
        </Grid>
    </>
);

AdminForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default AdminForm;
